import { post } from 'utility/http';

export const getSession = async (body?: any) => {
  let newUrl = '/ecommerce/session';

  try {
    if (body.length > 0) {
      body.forEach((element: any, index: number) => {
        if (index === 0) {
          newUrl += `?${element.key}=${element.value}`;
        } else {
          newUrl += `&${element.key}=${element.value}`;
        }
      });
      const response = await post(newUrl);
      return response;
    }
    if (body?.params && Object.keys(JSON.parse(body?.params)).length >= 1) {
      const dataTemp = Object.entries(JSON.parse(body?.params));
      dataTemp.forEach((element: any, index: number) => {
        if (index === 0) {
          newUrl += `?${element[0]}=${element[1]}`;
        } else {
          newUrl += `&${element[0]}=${element[1]}`;
        }
      });
      const response = await post(newUrl);
      return response;
    }
    if (body.session_id && body.lead_id && body.utm_discount) {
      const response = await post(
        `${newUrl}?session_id=${body.session_id}&lead_id=${body.lead_id}&utm_discount=${body.utm_discount}`
      );
      return response;
    }
    if (!body.session_id && body.lead_id && body.utm_discount) {
      const response = await post(
        `${newUrl}?lead_id=${body.lead_id}&utm_discount=${body.utm_discount}`
      );
      return response;
    }
    if (body.session_id && !body.lead_id && body.utm_discount) {
      const response = await post(
        `${newUrl}?session_id=${body.session_id}&utm_discount=${body.utm_discount}`
      );
      return response;
    }
    if (body.session_id && body.lead_id && !body.utm_discount) {
      const response = await post(
        `${newUrl}?session_id=${body.session_id}&lead_id=${body.lead_id}`
      );
      return response;
    }
    if (body.session_id && !body.lead_id && !body.utm_discount) {
      const response = await post(`${newUrl}?session_id=${body.session_id}`);
      return response;
    }
    if (!body.session_id && body.lead_id && !body.utm_discount) {
      const response = await post(`${newUrl}?lead_id=${body.lead_id}`);
      return response;
    }
    if (!body.session_id && !body.lead_id && body.utm_discount) {
      const response = await post(`${newUrl}?utm_discount=${body.utm_discount}`);
      return response;
    }
    const response = await post(newUrl);
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};
