import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from 'routes/routes';
import 'i18n/config';
import './App.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { REACT_APP_STRIPE_API_KEY } from 'config';
import Toastify from 'components/toast/Toastify';

const Layout = React.lazy(() => import('components/layout/Layout'));

function App() {
  const stripePromise = loadStripe(`${REACT_APP_STRIPE_API_KEY}`);

  return (
    <React.Suspense
      fallback={
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-lightgrayVikey opacity-75 flex flex-col items-center justify-center">
          <img src="/images/VikeyShopLogo.png" alt="" className="md:h-48 h-20 rounded-full" />
          <h2 className="text-center text-grayVikey md:text-md text-xs font-bold mt-5">
            Loading...
          </h2>
          <p className="text-center text-grayVikey mt-5 md:text-md text-xs">
            This may take a few seconds, please don't close this page.
          </p>
          <h2 className="text-center text-grayVikey md:text-md text-xs font-bold mt-5">
            Caricamento...
          </h2>
          <p className="text-center text-grayVikey mt-5 md:text-md text-xs">
            Questa operazione potrebbe richiedere alcuni secondi, per favore non chiudere questa
            pagina.
          </p>
        </div>
      }
    >
      <Elements
        stripe={stripePromise}
        options={{
          appearance: { theme: 'stripe', labels: 'floating' }
        }}
      >
        <Toastify />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              {routes.map((route: any) => (
                <Route key={route.path} path={route.path} element={<route.element />}>
                  {route?.children &&
                    route.children.map((rot: any) => (
                      <Route key={rot.path} path={rot.path} element={<rot.element />}></Route>
                    ))}
                </Route>
              ))}
            </Route>
          </Routes>
        </BrowserRouter>
      </Elements>
    </React.Suspense>
  );
}

export default App;
