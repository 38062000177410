import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { openToastify } from 'components/toast/OpenToast';
import { postAddPayMethod } from 'services/stepsAPI';

const initialState: {
  addPayMethod: {
    data: any;
    status: string;
  };
  isCheckout: {
    data: boolean;
    status: string;
  };
} = {
  addPayMethod: {
    data: null,
    status: 'idle'
  },
  isCheckout: {
    data: false,
    status: 'idle'
  }
};

export const postAddPayMethodState = createAsyncThunk(
  'checkout/postAddPayMethod',
  async (body: any) => {
    try {
      const response: any = await postAddPayMethod(body);
      return response.data;
    } catch (error: any) {
      openToastify('danger', 'something_missing', error?.data?.msg);
      return Promise.reject(error.data ? error.data : error);
    }
  }
);

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setIsCheckout: (state, action) => {
      state.isCheckout.data = action.payload;
    },
    resetAddPayMethod: (state) => {
      state.addPayMethod.data = null;
    },
    resetIsCheckout: (state) => {
      state.isCheckout.data = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(postAddPayMethodState.pending, (state) => {
        state.addPayMethod.status = 'loading';
      })
      .addCase(postAddPayMethodState.fulfilled, (state, action) => {
        state.addPayMethod.status = 'success';
        state.addPayMethod.data = action.payload;
        localStorage.clear();
      })
      .addCase(postAddPayMethodState.rejected, (state, action) => {
        state.addPayMethod.status = 'rejected';
        state.addPayMethod.data = action.error;
      });
  }
});

export const { setIsCheckout, resetAddPayMethod, resetIsCheckout } = checkoutSlice.actions;
export const checkoutState = (state: RootState) => state.checkout;
export const isCheckoutState = (state: RootState) => state.checkout.isCheckout;
export const addPayMethodState = (state: RootState) => state.checkout.addPayMethod;

export default checkoutSlice.reducer;
