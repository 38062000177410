import MotionContainer from 'components/shared/MotionContainer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetToast, toastState } from './ToastifySlice';

const Toastify = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toastStateSelector = useSelector(toastState);
  const success = 'text-greenVikey bg-green-100 rounded-vikey';
  const danger = 'text-redVikey bg-red-100 rounded-vikey';

  let colorType: any;
  let iconType: any;
  switch (toastStateSelector.data?.severity) {
    case 'success':
      iconType = (
        <svg
          className="flex-shrink-0 w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          ></path>
        </svg>
      );
      colorType = success;
      break;
    case 'danger':
      iconType = (
        <svg
          className="flex-shrink-0 w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      );
      colorType = danger;
      break;
    default:
      colorType = success;
      iconType = (
        <svg
          className="flex-shrink-0 w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          ></path>
        </svg>
      );
      break;
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (toastStateSelector.data) {
      const timeout = setTimeout(() => dispatch(resetToast()), 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [toastStateSelector]);

  if (!toastStateSelector.data) return null;
  return (
    <MotionContainer
      id="toast-notify"
      className="flex items-center w-full max-w-xs p-5 text-grayVikey bg-white rounded-full shadow-vikey fixed right-10 top-10 z-50"
      role="alert"
    >
      <div
        className={`inline-flex items-center justify-center flex-shrink-0 md:w-8 w-3 md:h-8 h-3 ${colorType}`}
      >
        {iconType}
      </div>
      <div className="ml-3 md:text-sm text-xs font-bold max-w-fit">
        {t(toastStateSelector.data?.message)}
        {toastStateSelector.data?.errorType ? ` - ${toastStateSelector.data?.errorType}` : null}
      </div>
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-grayVikey hover:text-gray-900 rounded-full focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8"
        data-dismiss-target="#toast-success"
        aria-label="Close"
        onClick={() => dispatch(resetToast())}
      >
        <svg
          className="flex-shrink-0 w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </MotionContainer>
  );
};

export default Toastify;
